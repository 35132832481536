body {
    height: 100vh;
    background-image: url("./Assets/BG_CS.svg");
    background-repeat: repeat-y;
    background-position: center;
    background-size: cover;
    overflow: hidden;
}

#text,
#shadow {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10vw; 
    letter-spacing: 2vw; 
    font-family: Bad Script;
    color: #fff;
}

#text {
    color: #333;
}

#shadow {
    text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #228DFF, 0 0 70px #228DFF, 0 0 80px #228DFF, 0 0 100px #228DFF, 0 0 150px #228DFF;
}

#glow {
    /* Animation Time: 3s  /  Delay: 1s */
    animation: neon1 linear infinite 3s 1.5s;
    will-change: opacity;
}

#blink {
    /* Animation Time: 2s  /  Delay: 0s */
    animation: blink linear infinite 2s 0s;
}

@keyframes neon1 {
    20% {
        opacity: 1;
    }
    80% {
        opacity: 0.6;
    }
}

@keyframes blink {
    70% {
        opacity: 1;
    }
    79% {
        opacity: 0;
    }
    81% {
        opacity: 1;
    }
    82% {
        opacity: 0;
    }
    83% {
        opacity: 1;
    }
    92% {
        opacity: 0;
    }
    92.5% {
        opacity: 1;
    }
}

/* Media queries for responsiveness */
@media screen and (min-width: 768px) {
    #text,
    #shadow {
        font-size: 7em; /* Adjust font size for larger screens */
        letter-spacing: 20px; /* Adjust letter spacing for larger screens */
    }
}
