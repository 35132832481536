* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}
html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

body {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.container {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100vh;
  overflow: hidden;
}

.container::before,
.container::after {
  content: "";
  position: absolute;
  width: 37000px;
  height: 37000px;
  animation: rotate 4s linear infinite;
}

.container::before {
  background-image: conic-gradient(transparent, transparent, transparent, #d400d4);
  height: 1000vh;
}

.container::after {
  background-image: conic-gradient(transparent, transparent, transparent, #00cfff);
  animation-delay: -2s;
  height: 1000vh;
}

.form-box {
  background: #0c1022;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 10;
}

.form-box h2 {
  font-size: 25px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
}

.input-box {
  position: relative;
  width: 100%;
  margin: 20px 0;
}

.input-box input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 0 10px;
  color: #fff;
  border-bottom: 1px solid #fff;
}

.input-box label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label {
  top: 0;
  font-size: 12px;
}

.sign-up {
  margin-top: 20px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.sign-up a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.navigation-buttons button {
  width: 45%;
}

button {
  height: 40px;
  border-radius: 40px;
  background: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #0c1022;
  font-weight: 500;
  margin-top: 30px;
  transition: background 0.3s, color 0.3s;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 480px) {
  .container {
    border-radius: 0;
    padding: 10px;
  }

  .form-box {
    border-radius: 0;
    padding: 20px;
  }
}